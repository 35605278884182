import React from "react";
import Marquee from "react-fast-marquee";
const TextSliderOne = () => {
  return (
    <>
      {/* Text Slider area start */}
      <section className="textslider__area" style={{marginBottom:"150px"}}>
        <div className="textslider__inner">
          <div className="swiper textslider__active">
            <div className="swiper-wrapper">
              <Marquee>
                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Creative<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Digital<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Marketing<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Web Dev<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Design<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Social Media<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Solutions<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Strategies<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Management<span>*</span>
                    </h2>
                  </div>
                </div>

                <div className="swiper-slide">
                  <div className="textslider__slide">
                    <h2 className="textslider__text">
                      Growth<span>*</span>
                    </h2>
                  </div>
                </div>
              </Marquee>
            </div>
          </div>
        </div>
      </section>
      {/* Text Slider area end */}
    </>
  );
};

export default TextSliderOne;
