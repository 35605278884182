import React from "react";
import { Link } from "react-router-dom"
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";



const ContactOne = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast("Form sent!", {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data;
    // if not valid 
    // return
    try {
      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      await emailjs.send(
        // "service_k64",
        process.env.REACT_APP_SERVICE_ID,
        // "template_k64",
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        // templateParams,
        // "j8_Z8Ri6AiMozGFFm"
        process.env.REACT_APP_USER_ID
      );

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <section className="contact__area">
        <div className="contact__top">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="from" data-aos="fade-right">
                  {/* <h2 className="form__title" style={{color:"#22f55d", opacity:"1"}}></h2> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact__btm">
          <div className="container">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div
                  className="contact__content pt-150 pb-120"
                  data-aos="fade-up"
                  data-aos-delay={300}
                >
                  <h3 className="sec-subtitle">
                    get in touch <span>get in touch</span>
                  </h3>
                  <h4 className="sec-title">
                  Submit your inquiry <br /> we'll be in touch
                  </h4>
                  <ul className="contact__media">
                    <li>
                      <span>
                        <i className="fa-solid fa-envelope" />
                      </span>
                      <div>
                        <Link to="mailto:kraft64media@gmail.com">
                          Email : kraft64media@gmail.com
                        </Link>
                        {/* <Link to="mailto:ompny@mail.com">
                          Sub Email: compny@mail.com
                        </Link> */}
                      </div>
                    </li>
                    <li>
                      <span>
                        <i className="fa-solid fa-phone" />
                      </span>
                      <div>
                        <Link to="https://wa.me/916358242840">
                          Phone : +91 6358242840{" "}
                        </Link>
                        {/* <Link to="tel:00123456789">
                          Mobile : 00 123 456 789
                        </Link> */}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6">
                <div className="contact__form">
                  <div className="" data-aos="fade-up" data-aos-delay={600}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                    style={{marginBottom:"0px"}}
                        type="text"
                        name="name"
                        {...register("name", {
                          required: {
                            value: true,
                            message: "Please enter your name*",
                          },
                          maxLength: {
                            value: 30,
                            message:
                              "Please use 30 characters or less",
                          },
                        })}
                        placeholder="Name"
                      ></input>
                      {errors.name && (
                        <span className="errorMessage">
                          {errors.name.message}
                        </span>
                      )}
                      <input
                      style={{marginBottom:"0px", marginTop:"30px"}}
                        type="number"
                        name="phone"
                        {...register("phone", {
                          required: {
                            value: true,
                            message: "Please enter a valid Number*",
                          },
                          maxLength: {
                            value: 75,
                            message:
                              "phone cannot exceed 20 characters",
                          },
                        })}
                        placeholder="Phone Number"
                      ></input>
                      {errors.phone && (
                        <span className="errorMessage">
                          {errors.phone.message}
                        </span>
                      )}
                      <input
                      style={{marginBottom:"0px", marginTop:"30px"}}
                        type="email"
                        name="email"
                        {...register("email", {
                          required: true,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                        placeholder="Email address"
                      ></input>
                      {errors.email && (
                        <span className="errorMessage">
                          Please enter a valid email address*
                        </span>
                      )}
                      <textarea
                      style={{marginBottom:"0px", marginTop:"30px"}}
                        name="message"
                        {...register("message", {
                          required: true,
                        })}
                        placeholder="Message"
                      >
                      </textarea>
                      {errors.message && (
                        <span className="errorMessage">
                          Please enter a message*
                        </span>
                      )}
                      <button className="submit btn-rollover" type="submit" style={{marginTop:"30px"}}>
                        Submit
                        <i className="fa-solid fa-arrow-right" />
                      </button>
                    </form>
                  </div>
                </div>
              </div>
                    <ToastContainer />
            </div>
          </div>
        </div>
      </section>
      {/* Contact area end */}
    </>
  );
};

export default ContactOne;
