import React from "react";
import { Link } from "react-router-dom";

const FaqOne = () => {
  return (
    <>
      {/* FAQs area start */}
      <section className="faq__area pt-170 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="faq__content" data-aos="fade-up">
                <h2 className="sec-subtitle text-white">
                  some faq’s <span>some faq’s</span>
                </h2>
                <h3 className="sec-title text-white">
                  Powering your <br /> digital potential
                </h3>
                <p>
                Explore the potential of your brand with kraft64.com. Our FAQ section answers crucial questions about digital agency services, ensuring you make informed decisions.{" "}
                </p>
                <div className="db-arrow-up">
                  <Link to="/about">
                    <img
                      className="img"
                      src="assets/imgs/icon/arrow-xxl-2.png"
                      alt="Arrow Icon"
                    />
                    <img
                      className="img-2"
                      src="assets/imgs/icon/arrow-xxl-2.png"
                      alt="Arrow Icon"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="faq__list">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item" data-aos="fade-up">
                    <h2 className="" id="headingOne">
                      <button
                        className="accordion-button"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Can Kraft64 handle my social media management?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        Social media mastery is one of our core competencies. We can help enhance your brand's presence on various platforms, engage with your audience, and implement effective social media strategies to boost your online visibility.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={300}
                  >
                    <h2 className="" id="headingTwo">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Do you provide ongoing support after completing a project?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        Yes, we value long-term partnerships. After completing a project, we offer ongoing support and maintenance services to ensure your digital assets continue to perform optimally. You can choose from our support packages based on your specific needs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={600}
                  >
                    <h2 className="" id="headingThree">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        What services do you offer?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        Kraft64 specializes in a range of digital services, including pixel-perfect design, creative edge solutions, and social media mastery. Our expertise extends to strategic growth, innovative web development, and digital marketing strategies. Explore our Services section for a detailed overview.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={900}
                  >
                    <h2 className="" id="headingFour">
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        How much do your digital services cost?
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <p>
                        At Kraft64, we offer tailored digital solutions, and the cost varies based on your specific requirements. Contact us for a personalized quote that aligns with your budget and objectives.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* FAQs area end */}
    </>
  );
};

export default FaqOne;
