import React from "react";

const WhoWeAreTwo = () => {
  return (
    <>
      {/* Who we are area start */}
      <section className="who__area-6 pt-170 pb-120">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-6">
              <div className="who__left">
                <div className="sec-title-wrap">
                  <h2 className="sec-subtitle-2">who we are </h2>
                  <h3 className="sec-title underline-2">
                    Transforming ideas <br /> into reality
                  </h3>
                </div>
                <div className="who__features">
                  <div className="who__feature">
                    <div className="number">
                      <span className="underline-2">01</span>
                    </div>
                    <div className="who__content">
                      <h4 className="title underline">Website & Landing Page Design</h4>
                      <p>
                      Crafting unique illustrations for websites, marketing materials & more.
                      </p>
                    </div>
                  </div>
                  <div className="who__feature">
                    <div className="number">
                      <span className="underline-2">02</span>
                    </div>
                    <div className="who__content">
                      <h4 className="title underline">Graphic Designs</h4>
                      <p>
                      Captivating visuals for brand stories. Tailored designs for websites, marketing, and more.
                      </p>
                    </div>
                  </div>
                  <div className="who__feature">
                    <div className="number">
                      <span className="underline-2">03</span>
                    </div>
                    <div className="who__content">
                      <h4 className="title underline">Creative Illustrations</h4>
                      <p>
                      Eye-catching visuals for your brand story. Custom illustrations for websites, marketing, and more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-6">
              <div className="who__right">
                <div className="main-img">
                  <img src="https://ik.imagekit.io/k64/k64/whoweare.jpeg" alt="kraft64" style={{width:"588px", height:"706px", overflow:"hidden", objectFit:"cover"}} />
                </div>
                <div className="who__project">
                  <div className="project-completed">
                    <img src="assets/imgs/who/6/hand.png" alt="kraft64" />
                    {/* <h3 className="total counter_fast"></h3> */}
                    <p style={{textAlign:"center"}}>Emphasizing<br/>network</p>
                  </div>
                </div>
                <img
                  src="assets/imgs/shape/7.png"
                  alt="Shape"
                  className="shape"
                />
                <img
                  src="assets/imgs/shape/12.png"
                  alt="Shape"
                  className="shape-2"
                />
                <img
                  src="assets/imgs/shape/13.png"
                  alt="Shape"
                  className="shape-3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Who we are area end */}
    </>
  );
};

export default WhoWeAreTwo;
