import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const HeaderOne = () => {
  const [search, setSearch] = useState(false);
  const [offCanvas, setOffCanvas] = useState(false);

  useEffect(() => {
    var offCanvasNav = document.getElementById("offcanvas-navigation");
    var offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML(
        "beforebegin",
        "<span class='menu-expand'><i></i></span>"
      );
    }

    var menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    var numMenuExpand = menuExpand.length;

    function sideMenuExpand() {
      if (this.parentElement.classList.contains("active") === true) {
        this.parentElement.classList.remove("active");
      } else {
        for (let i = 0; i < numMenuExpand; i++) {
          menuExpand[i].parentElement.classList.remove("active");
        }
        this.parentElement.classList.add("active");
      }
    }

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", sideMenuExpand);
    }
  }, []);

// const searchControl = () => {
//   setSearch(!search);
// };
  const offCanvasControl = () => {
    setSearch(false);
    setOffCanvas(!offCanvas);
  };
  return (
    <>
      {/* Header area start */}
      <header className="header__area pos-abs plr-100">
        <div className="header__inner">
          <div className="header__logo">
            <Link to="/">
              <img src="/assets/imgs/logo/logo.png" alt="Site Logo" style={{filter:"invert(1)", width:"120px", height:"auto"}}/>
            </Link>
          </div>
          <div className="header__menu">
            <nav className="main-menu">
              <ul>
                <li className="">
                  <Link to="#">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li className="">
                  <Link to="/services">Our Services</Link>
                </li>
                {/* <li className="">
                  <Link to="#">pages</Link>
                </li> */}
                {/* <li className="">
                  <Link to="#">blog</Link>
                </li> */}
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </nav>
          </div>
          <div className="header__others">
            <div className="header__offcanvas">
              <button className="menu_icon" onClick={offCanvasControl}>
                <img src="/assets/imgs/icon/menu.png" alt="Menu Icon" />
              </button>
            </div>
          </div>
        </div>
      </header>
      <div className={!search ? "search__form" : "search__form showed"}>
        <form action="#">
          <input type="text" name="s" placeholder="Search..." />
        </form>
      </div>
      {/* Offcanvas area start */}
      <div
        className={!offCanvas ? "offcanvas__area" : "offcanvas__area showed"}
      >
        <div className="offcanvas__inner">
          <div className="offcanvas__top">
            <img src="assets/imgs/logo/logo.png" alt="Logo" style={{filter:"contrast(0.5)", width:"120px", height:"auto"}}/>
            <button id="offcanvas_close" onClick={offCanvasControl}>
              <i className="fa-solid fa-xmark" />
            </button>
          </div>
          <div className="offcanvas__search">
            <form action="#">
              <input type="text" placeholder="Search..." name="s" />
            </form>
          </div>
          <div className="offcanvas__menu" >
            <nav className="offcanvas-navigation" id="offcanvas-navigation">
              <ul>
                <li className="">
                  <Link to="/">HOME</Link>
                </li>

                <li className="">
                  <Link to="#">Services</Link>
                </li>

                <li className="">
                  <Link to="/about">Pages</Link>
                </li>
                <li className="">
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/contact">CONTACT</Link>{" "}
                </li>
              </ul>
            </nav>
          </div>
          {/* <div className="offcanvas__map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1511.2499674845235!2d-73.99553882767792!3d40.75102778252164!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1686536419224!5m2!1sen!2sbd"
              loading="lazy"
              title="kraft64"
            />
          </div>
           <div className="offcanvas__btm">
            <div className="footer__address-3">
              <ul>
                <li>
                  <span>
                    <i className="fa-solid fa-location-dot" />
                  </span>
                  <p className="text-white">
                    Burmsille Street, MN 55337, <br />
                    United States
                  </p>
                </li>
                <li>
                  <span>
                    <i className="fa-solid fa-phone" />
                  </span>
                  <div>
                    <Link to="tel:+88014420420">+(1) 123 456 7890</Link>
                    <Link to="tel:+88014420420">+(1) 098 765 4321</Link>
                  </div>
                </li>
                <li>
                  <span>
                    <i className="fa-solid fa-envelope" />
                  </span>
                  <div>
                    <Link to="mailto:info@driller.com">info@driller.com</Link>
                    <Link to="mailto:info.example@driller.com">
                      info.example@driller.com
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className="footer__social-3">
              <ul>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-facebook-f" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-twitter" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="fa-brands fa-instagram" />
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
      {/* Offcanvas area end */}
    </>
  );
};

export default HeaderOne;
