import React from "react";
// import { Link } from "react-router-dom";

const AboutTwo = () => {
  return (
    <>
      {/* About area start */}
      <section className="about__area-6 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__imgs-6">
                <div className="image-1">
                  <img src="https://ik.imagekit.io/k64/k64/abt-s2.jpg" alt="kraft64" style={{width:"458px", height:"555px", overflow:"hidden", objectFit:"cover"}} />
                  {/* <Link
                    className="play popup-link"
                    to="https://www.youtube.com/watch?v=FYpsf0ACkmI"
                  >
                    <i className="fa-solid fa-play" />
                  </Link> */}
                </div>
                <div className="image-2">
                  <img src="https://ik.imagekit.io/k64/k64/abt-s1.jpg" alt="kraft64" style={{width:"350px", height:"361px", overflow:"hidden", objectFit:"cover"}}  />
                </div>
                <div className="bell">
                  <span>
                    <i className="fa-regular fa-bell" />
                  </span>
                </div>
                <div className="chart">
                  <span>
                    <i className="fa-solid fa-chart-column" />
                  </span>
                </div>
                <div className="projects">
                  <img src="assets/imgs/about/6/medal.png" alt="Medal Icon" />
                  <p>
                    <span>10+</span>Projects Completed
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__content-6">
                <h2 className="sec-subtitle-2">ABOUT US </h2>
                <h3 className="sec-title underline-2">
                  Innovative solutions for a digital world
                </h3>
                <p>
                At kraft64.com, we believe in innovative solutions that redefine your digital experience. Our team of experts works tirelessly to deliver exceptional results, ensuring your brand stands out in the online landscape.<br/>How We Transform Ideas into Reality:
                </p>
                <ul>
                  <li>We make innovations meet strategies </li>
                  <li>Designs that transforms into a captivating experiences</li>
                  <li>Every project is a journey of extraordinary possibilities</li>
                </ul>
                {/* <Link className="db-btn-arrow" to="#">
                  Read More <i className="fa-solid fa-arrow-right" />
                </Link> */}
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/shape/6.png"
          alt="Zigzag Shape"
          className="shape"
        />
      </section>
      {/* About area end */}
    </>
  );
};

export default AboutTwo;
