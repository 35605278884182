import React from "react";
import TrackVisibility from "react-on-screen";
// import CountUp from "react-countup";
import { Link } from "react-router-dom";
const AboutOne = () => {
  return (
    <>
      {/* About area start */}
      <section className="about__area pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div className="about__imgs" data-aos="fade-up">
                <div className="image-1">
                  <img src="https://ik.imagekit.io/k64/k64/abt-s2.jpg" alt="kraft64" style={{width:"458px", height:"555px", overflow:"hidden", objectFit:"cover"}}/>
                  {/* <Link
                    className="play popup-link"
                    to="https://www.youtube.com/watch?v=FYpsf0ACkmI"
                  >
                    <i className="fa-solid fa-play" />
                  </Link> */}
                </div>
                <div className="image-2">
                  <img src="https://ik.imagekit.io/k64/k64/abt-s1.jpg" alt="kraft64"  style={{width:"350px", height:"272px", overflow:"hidden", objectFit:"cover"}} />
                </div>
                <div className="bell">
                  <span>
                    <i className="fa-regular fa-bell" />
                  </span>
                </div>
                <div className="projects">
                  <img src="assets/imgs/icon/medal.png" alt="Medal Icon" />
                  <div>
                    <span className="counter_fast">
                      <TrackVisibility once>
                        {({ isVisible }) =>
                          isVisible && (
                            <span className="d-inline-flex">
                              10+
                            </span>
                          )
                        }
                      </TrackVisibility>
                    </span>
                    Projects Completed
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-xl-6 col-md-6">
              <div
                className="about__content"
                data-aos="fade-up"
                data-aos-delay={500}
              >
                <h2 className="sec-subtitle heading-animation">
                  ABOUT US <span>About Us</span>
                </h2>
                <h3 className="sec-title">
                  Innovative solutions for a digital world
                </h3>
                <p>
                At kraft64.com, we believe in innovative solutions that redefine your digital experience. Our team of experts works tirelessly to deliver exceptional results, ensuring your brand stands out in the online landscape.<br/>How We Transform Ideas into Reality:
                </p>
                <ul>
                  <li>We make innovations meet strategies </li>
                  <li>Designs that transforms into a captivating experiences</li>
                  <li>Every project is a journey of extraordinary possibilities</li>
                </ul>
                <Link className="db-btn-arrow" to="/about">
                  Read More <i className="fa-solid fa-arrow-right" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/imgs/shape/6.png"
          alt="Zigzag Shape"
          className="shape"
        />
      </section>
      {/* About area end */}
    </>
  );
};

export default AboutOne;
