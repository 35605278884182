import React from "react";
import { Link } from "react-router-dom";
import ContactForm from "./ContactOne"
const Contact = () => {
  return (
    <>
      {/* contact */}
      <section className="contact__area-6 pt-150 pb-150">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6">
                  <div className="contact__item">
                    <i className="fa-solid fa-location-dot" />
                    <h4 className="title">Location</h4>
                    <p> Vadodara, Delhi and Gurugram.</p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact__item">
                    <i className="fa-solid fa-phone" />
                    <h4 className="title">Call us</h4>
                    <p>
                    <Link to="https://wa.me/916358242840">+91 6358242840</Link>
                    </p>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact__item">
                    <i className="fa-solid fa-envelope" />
                    <h4 className="title">Email</h4>
                    <Link to="mailto:kraft64media@gmail.com">
                      kraft64media@gmail.com
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="contact__item">
                    <i className="fa-solid fa-share" />
                    <h4 className="title">Social Media</h4>
                    <p>
                      coming soon
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <iframe
                title="kraft64"
                className="w-100"
                height={500}
                style={{filter:"hue-rotate(45deg)"}}
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224568.15030524903!2d76.9897491!3d28.42295755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19d582e38859%3A0x2cf5fe8e5c64b1e!2sGurugram%2C%20Haryana!5e0!3m2!1sen!2sin!4v1710446756242!5m2!1sen!2sin"
              />
            </div>
          </div>
          {/* <div className="blog__form">
            <span className="blog__form-title">Leave a reply</span>
            <form>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="user_name"
                    id="Name"
                    placeholder="Name"
                  />
                </div>
                <div className="col-md-6">
                  <input
                    type="email"
                    name="user_email"
                    id="E-mail"
                    placeholder="E-mail"
                  />
                </div>
                <div className="col-md-12">
                  <textarea
                    name="msg"
                    id="msg"
                    placeholder="Comment"
                    defaultValue={""}
                  />
                </div>
                <div className="contact__submitwrap">
                  <button
                    className="contact__submit btn-rollover"
                    type="submit"
                  >
                    Send now <i className="fa-solid fa-arrow-right" />
                  </button>
                </div>
              </div>
            </form>
          </div> */}
        </div>
      </section>
      <ContactForm/>

      {/* /contact */}
    </>
  );
};

export default Contact;
