import React from "react";
// import { Link } from "react-router-dom"
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";


const NewsletterOne = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast("You are on our list!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    const {email } = data;
    try {
      const templateParams = {
        email,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_NEWSLETTER_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      );
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      {/* Newsletter area start */}
      <section className="newsletter__area">
        <div className="container">
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-5">
              <div className="newsletter__left" data-aos="fade-right">
                <img src="https://ik.imagekit.io/k64/k64/newsletter.jpeg" alt="kraft64"  style={{width:"501px", height:"609px", overflow:"hidden", objectFit:"cover"}} />
              </div>
            </div>
            <div className="col-xxl-7 col-xl-7 col-lg-7">
              <div
                className="newsletter__right pt-120 pb-120"
                data-aos="fade-up"
                data-aos-delay={300}
              >
                <h2 className="sec-subtitle">
                  Newsletter <span>Newsletter</span>
                </h2>
                <h3 className="sec-title">Want to Be a Member?</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    type="email"
                    name="email"
                    {...register("email", {
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    })}
                    placeholder="Email address"
                  ></input>
                  {errors.email && (
                    <span className="errorMessage">
                      Please enter a valid email address*
                    </span>
                  )}
                  <button type="submit" className="submit btn-rollover">
                    Subscribe Now
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Newsletter area end */}
    </>
  );
};

export default NewsletterOne;
