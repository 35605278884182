import React from "react";
import { Link } from "react-router-dom";

const ServiceDetails = () => {
  return (
    <>
      {/* Service Details area start */}
      <section className="service__details-area pb-150 pt-150">
        <div className="container">
          <div className="service__details top pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="https://ik.imagekit.io/k64/k64/services/hero2.jpg"
                  style={{width:"630px", height:"366px", overflow:"hidden", objectFit:"cover"}}
                  alt="kraft64" />
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="service__details-title">
                  ELEVATE YOUR BRAND, ENHANCE YOUR DIGITAL PRESENCE!
                  </h2>
                  <p>
                  At Kraft64, we offer a comprehensive range of digital services designed to propel your brand to new heights in the digital landscape.
                  </p>
                  <ul className="list-angle">
                    <li>
                    Ready to take your brand to the next level? Click the button below to make an inquiry about our services and discover how we can help you achieve your digital goals.
                    </li>
                  </ul>
                  <Link className="db-btn-primary btn-rollover" to="https://wa.me/916358242840">
                    Get started <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="service__details mid pb-140">
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="content">
                  <h2 className="service__details-title">
                  THE POWER OF DIGITAL, THE REACH OF MARKETING
                  </h2>
                  <p>
                  In today's competitive market, leveraging digital marketing strategies is essential for success. Kraft64 offers a suite of services tailored to meet your brand's unique needs:
                  </p>
                  <div className="feature">
                    <div className="icon">
                      <img src="assets/imgs/icon/phone.png" alt="Icon" />
                    </div>
                    <div className="info">
                      <h4 className="title">Digital Advertising</h4>
                      <p>
                      Reach your target audience effectively with strategic digital advertising campaigns. From PPC to display ads, we help you maximize your ROI and drive conversions.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="feature">
                    <div className="icon">
                      <img src="assets/imgs/icon/phone.png" alt="Icon" />
                    </div>
                    <div className="info">
                      <h4 className="title">Content Marketing</h4>
                      <p>
                      Establish your brand as an industry leader with our Content Marketing service. We create valuable and relevant content that attracts and retains your audience, driving engagement and loyalty.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6">
                <div className="img">
                  <img src="https://ik.imagekit.io/k64/k64/services/hero1.jpg"
                      style={{width:"630px", height:"396px", overflow:"hidden", objectFit:"cover"}} 
                  alt="kraft64" />
                </div>
              </div>
              <div className="col-xxl-12">
                <p>
                As a digital agency, we understand the dynamic landscape of online business. Our Marketing service is crafted to provide strategic solutions tailored specifically for digital platforms. From comprehensive market analysis to strategic planning, we empower your brand to navigate the digital terrain with confidence. With our Advertising service, we design and execute innovative campaigns that cut through the digital noise, ensuring your message resonates with your target audience effectively. Additionally, our Content Marketing service is finely tuned to address the unique challenges faced by digital businesses, delivering valuable content that engages and converts online audiences. Trust us to elevate your digital presence and drive tangible results in the ever-evolving digital space.                
                </p>
              </div>
            </div>
          </div>
          <div className="service__details btm">
            <div className="row">
              <div className="col-xxl-12">
                <h2 className="service__details-title">
                  Explore Our <br /> Services
                </h2>
              </div>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="more-service__item">
                  <div>
                    <img
                      src="https://ik.imagekit.io/k64/k64/services/seo.jpeg"
                      style={{width:"410px", height:"268px", overflow:"hidden", objectFit:"cover"}}
                      alt="kraft64"
                    />
                  </div>
                  <div className="more-service__content">
                    <div>
                      <h3 className="more-service__title">SEO/SEM and Campaigns</h3>
                    </div>
                    <p>
                    Optimizing online visibility & running effective ad campaigns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="more-service__item">
                  <div>
                    <img
                      src="https://ik.imagekit.io/k64/k64/services/social2.jpeg"
                      style={{width:"410px", height:"268px", overflow:"hidden", objectFit:"cover"}}
                      alt="kraft64"
                    />
                  </div>
                  <div className="more-service__content">
                    <div>
                      <h3 className="more-service__title">Social Media Management</h3>
                    </div>
                    <p>
                    Strategically managing social platforms for brand growth.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="more-service__item">
                  <div>
                    <img
                      src="https://ik.imagekit.io/k64/k64/services/content.jpeg"
                      style={{width:"410px", height:"268px", overflow:"hidden", objectFit:"cover"}}
                      alt="kraft64"
                    />
                  </div>
                  <div className="more-service__content">
                    <div>
                      <h3 className="more-service__title">Content Curation and Marketing</h3>
                    </div>
                    <p>
                    Crafting and promoting engaging content for audience attraction.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="more-service__item">
                  <div>
                    <img
                      src="https://ik.imagekit.io/k64/k64/services/website.jpeg"
                      style={{width:"410px", height:"268px", overflow:"hidden", objectFit:"cover"}}
                      alt="kraft64"
                    />
                  </div>
                  <div className="more-service__content">
                    <div>
                      <h3 className="more-service__title">Website Development</h3>
                    </div>
                    <p>
                    Building and enhancing user-friendly websites for businesses.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="more-service__item">
                  <div>
                    <img
                      src="https://ik.imagekit.io/k64/k64/services/design.jpg"
                      style={{width:"410px", height:"268px", overflow:"hidden", objectFit:"cover"}}
                      alt="kraft64"
                    />
                  </div>
                  <div className="more-service__content">
                    <div>
                      <h3 className="more-service__title">Graphics Designing</h3>
                    </div>
                    <p>
                    Creating visually appealing designs for branding and marketing.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="more-service__item">
                  <div>
                    <img
                      src="https://ik.imagekit.io/k64/k64/services/marketing-solutions.jpeg"
                      style={{width:"410px", height:"268px", overflow:"hidden", objectFit:"cover"}}
                      alt="kraft64"
                    />
                  </div>
                  <div className="more-service__content">
                    <div>
                      <h3 className="more-service__title">Marketing Solutions</h3>
                    </div>
                    <p>
                    Implementing innovative strategies to drive business growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Service Details area end */}
    </>
  );
};

export default ServiceDetails;
