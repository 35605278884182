import React from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

const FooterOne = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast("You are on our list!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success",
      toastId: "notifyToast",
    });
  };

  const onSubmit = async (data) => {
    const {email } = data;
    try {
      const templateParams = {
        email,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_NEWSLETTER_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      );
      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <>
      {/* Footer area start */}
      <footer className="footer__area">
        {/* <div className="footer__top">
          <div className="container">
            <div className="row">
              <div className="col-xxl-12">
                <div className="footer__map">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d1511.2499674845235!2d-73.99553882767792!3d40.75102778252164!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1686536419224!5m2!1sen!2sbd"
                    loading="lazy"
                    title="kraft64"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="footer__widgets" style={{paddingTop:"150px", marginTop:"150px"}}>
          <div className="container">
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                <div className="footer__logo">
                  <img
                    src="assets/imgs/logo/logo.png"
                    alt="Footer Logo"
                    style={{width:"120px", height:"auto", filter:"contrast(0.5)"}}
                  />
                  <p>
                  We turn imaginative ideas into captivating realities, embodying our passion for creativity in every project.
                  </p>
                  <Link className="db-btn-arrow" to="/services">
                    Read More <i className="fa-solid fa-arrow-right" />
                  </Link>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                <div className="footer__address">
                  <h2 className="footer__title">Address</h2>
                  <ul>
                    <li>
                      <span>
                        <i className="fa-solid fa-paper-plane" />
                      </span>
                      <p>
                        Vadodara, Delhi and Gurugram.
                      </p>
                    </li>
                    <li>
                      <span>
                        <i className="fa-solid fa-phone" />
                      </span>
                      <Link to="https://wa.me/916358242840">+91 6358242840</Link>
                    </li>
                    <li>
                      <span>
                        <i className="fa-solid fa-envelope" />
                      </span>
                      <Link to="mailto:kraft64media@gmail.com">
                        kraft64media@gmail.com
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                <div className="footer__service">
                  <h2 className="footer__title">More Servicve</h2>
                  <ul>
                    <li>
                      <Link to="/services">Ui/Ux Design</Link>
                    </li>
                    <li>
                      <Link to="/services">2d/3d Animation</Link>
                    </li>
                    <li>
                      <Link to="/services">Digital Marketing</Link>
                    </li>
                    <li>
                      <Link to="/services">Website Development</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6">
                <div className="footer__newsletter">
                  <div className="footer__title">Newsletter</div>
                  <p>
                    Subscribe to our Newsletter for latest Tech Facts 
                  </p>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <input
                      type="email"
                      name="email"
                      {...register("email", {
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      })}
                      placeholder="Email address"
                    ></input>
                    {errors.email && (
                      <span style={{color:"white"}} className="errorMessage">
                        Please enter a valid email address*
                      </span>
                    )}
                    <button type="submit" className="submit">
                      <i className="fa-solid fa-paper-plane" />
                    </button>
                  </form>
                </div>
              </div>
              <div className="col-xxl-12">
                <div className="footer__btm">
                  <div className="footer__copyright">
                    <p>© kraft64 2024 | All Rights Reserved</p>
                  </div>
                  <div className="footer__social">
                    <ul>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-facebook-f" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-twitter" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="fa-brands fa-instagram" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* Footer area end */}
    </>
  );
};

export default FooterOne;
